<template>
  <div>
    <a-modal :visible="visible" title="持仓检查" @ok="commit" @cancel="$emit('disable')">
      <a-textarea placeholder="" :rows="10" v-model="data" />
    </a-modal>
    <a-modal
      :visible="resultModalVisible"
      title="持仓结果"
      :footer="null"
      @cancel="() => (resultModalVisible = false)"
    >
      只在持仓数据中： {{ this.onlyInHoldName }} <br />
      <br />
      只在输入文本中：{{ this.onlyInTextName }} <br />
      <br />
      只在持仓数据中：{{ this.onlyInHoldCode }} <br />
      <br />
      只在输入文本中：{{ this.onlyInTextCode }}
    </a-modal>
  </div>
</template>

<script>
import _ from 'lodash'

// 中文正则
const PATTERN_NAME = new RegExp('[\u4E00-\u9FA5]+')
const PATTERN_CODE = new RegExp('^\\d{6}$')

export default {
  name: 'AccountHoldCheckModal',
  props: {
    visible: Boolean,
    holdList: [],
  },
  data() {
    return {
      data: '',
      resultModalVisible: false,
    }
  },
  methods: {
    commit: function () {
      console.log(this.textNameList)
      console.log(this.holdNameList)
      console.log(this.textCodeList)
      console.log(this.holdCodeList)
      this.resultModalVisible = true
      this.$emit('disable')
    },
  },
  computed: {
    holdNameList() {
      return this.holdList.map(r => r.codeKey.name)
    },
    holdCodeList() {
      return this.holdList.map(r => r.codeKey.code)
    },
    textNameList() {
      return this.data
        .split('\n')
        .filter(r => !_.isEmpty(r))
        .filter(r => PATTERN_NAME.test(r))
    },
    textCodeList() {
      return this.data
        .split('\n')
        .filter(r => !_.isEmpty(r))
        .filter(r => PATTERN_CODE.test(r))
    },
    onlyInTextName() {
      return _.differenceBy(this.textNameList, this.holdNameList)
    },
    onlyInHoldName() {
      return _.differenceBy(this.holdNameList, this.textNameList)
    },
    onlyInTextCode() {
      return _.differenceBy(this.textCodeList, this.holdCodeList)
    },
    onlyInHoldCode() {
      return _.differenceBy(this.holdCodeList, this.textCodeList)
    },
  },
}
</script>

<style scoped></style>
