<template>
  <a-modal
    :visible="this.$store.state.stockHistoryModalVisible"
    :title="'交易图 - ' + this.$store.state.clickItem.codeName"
    @cancel="handle"
    :footer="null"
    :width="800"
    :bodyStyle="{ height: '500px' }"
  >
    <a-date-picker
      placeholder="开始时间"
      :value="this.startDateStr"
      valueFormat="YYYYMMDD"
      @change="value => (this.startDateStr = value)"
      :style="{ margin: '5px' }"
    />
    <a-date-picker
      placeholder="结束时间"
      :value="this.endDateStr"
      valueFormat="YYYYMMDD"
      @change="value => (this.endDateStr = value)"
      :style="{ margin: '5px' }"
    />
    <a-button type="primary" @click="fetchDataset">查询</a-button>
    <v-chart className="chart" :option="this.echartsOption" :loading="this.loading" />
  </a-modal>
</template>

<script>
import VChart, { THEME_KEY } from 'vue-echarts'
import axios from 'axios'
import _ from 'lodash'

export default {
  name: 'StockHistoryRecordModal',
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: 'dark',
  },
  data() {
    return {
      startDateStr: '',
      endDateStr: '',
      resDataset: [],
      loading: true,
    }
  },
  computed: {
    echartsOption: function () {
      return {
        legend: {},
        // 显示同列数据
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
          },
        },
        // 数据
        dataset: {
          dimensions: ['date', 'priceClose', 'BUY', 'SELL', '溢价率'],
          source: this.resDataset,
        },
        xAxis: { type: 'category' },
        // 坐标允许不从 0 开始
        yAxis: [{ scale: true }, { scale: true }],
        series: [
          {
            type: 'line',
            // markPoint: {
            //   data: this.allTradePointList,
            // },
          },
          { type: 'bar' },
          { type: 'bar' },
          { type: 'line', yAxisIndex: 1 },
        ],
      }
    },
    allTradePointList() {
      return _.concat(
        this.resDataset
          .filter(r => _.has(r, 'SELL'))
          .map(r => {
            return { coord: [r.date, r.SELL], value: '卖出', itemStyle: { color: 'green' } }
          }),
        this.resDataset
          .filter(r => _.has(r, 'BUY'))
          .map(r => {
            return { coord: [r.date, r.BUY], value: '买入', itemStyle: { color: 'red' } }
          })
      )
    },
  },
  methods: {
    //
    handle() {
      this.$store.commit('updateStockHistoryModalVisible', false)
    },
    // 获取价格趋势数据
    fetchDataset() {
      this.loading = true
      axios
        .post('/api/back/stock/price/single', {
          recordType: this.$store.state.clickAccountKey,
          codeKeyBO: this.$store.state.clickItem.codeKey,
          startDateStr: _.isEmpty(this.startDateStr) ? null : this.startDateStr,
          endDateStr: _.isEmpty(this.endDateStr) ? null : this.endDateStr,
        })
        .then(response => response.data)
        .then(res => {
          this.resDataset = res.data.dataset
          this.loading = false
        })
    },
  },
  watch: {
    '$store.state.stockHistoryModalVisible': function (newVal, oldVal) {
      if (!oldVal && newVal) {
        // 是打开时
        this.fetchDataset()
      }
    },
  },
}
</script>

<style scoped></style>
