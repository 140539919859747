<template>
  <a-layout id="components-layout-demo-side" style="min-height: 100vh">
    <a-layout-sider v-model="collapsed" collapsible>
      <div class="logo">
        <img src="@/assets/logo.png" height="100%" />
      </div>
      <a-menu theme="dark" :default-selected-keys="['1']" mode="inline">
        <!--    第一个菜单    -->
        <a-menu-item key="1">
          <router-link to="/convert_bond">
            <a-icon type="pie-chart" />
            <span>策略持仓</span>
          </router-link>
        </a-menu-item>
        <!--    第二个菜单    -->
        <a-menu-item key="2">
          <router-link to="/option_analysis">
            <a-icon type="pie-chart" />
            <span>期权分析</span>
          </router-link>
        </a-menu-item>
        <!--   vue 测试页面     -->
        <a-sub-menu key="sub1">
          <span slot="title"><a-icon type="user" /><span>User</span></span>
          <a-menu-item key="3"> Tom </a-menu-item>
          <a-menu-item key="4"> Bill </a-menu-item>
          <a-menu-item key="5"> Alex </a-menu-item>
        </a-sub-menu>
        <a-menu-item key="test">
          <router-link to="/test"><a-icon type="read" />Test</router-link>
        </a-menu-item>
        <!--   vue 参考资料     -->
        <a-menu-item key="vue">
          <router-link to="/vue"><a-icon type="read" />Vue</router-link>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <!--      <a-layout-header :style="{ background: '#fff', padding: 0 }" />-->
      <a-layout-content :style="{ overflow: 'initial' }">
        <router-view />
      </a-layout-content>
      <a-layout-footer :style="{ textAlign: 'center' }">
        Shiqi ©2022 Created by Ant UED
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script>
export default {
  data() {
    return {
      collapsed: false,
    }
  },
}
</script>

<style>
#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  text-align: center;
}
</style>
