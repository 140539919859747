<template>
  <div id="todo-list-example">
    <form v-on:submit.prevent="addNewTodo">
      <label for="new-todo">Add a todo</label>
      <input v-model="newTodoText" id="new-todo" placeholder="E.g. Feed the cat" />
      <button>Add</button>
    </form>
    <ul>
      <li
        is="todo-item"
        v-for="(todo, index) in todos"
        v-bind:key="todo.id"
        v-bind:title="todo.title"
        v-on:remove="todos.splice(index, 1)"
      ></li>
    </ul>
  </div>
</template>

<script>
// import Vue from 'vue'
//
// Vue.component('todo-item', {
//   template: `
//     <li>
//       {{ title }}
//       <button v-on:click="$emit('remove')">Remove</button>
//     </li>
//   `,
//   props: ['title'],
// })

import TodoItem from '@/components/learning/v1/TodoItemExample'

export default {
  name: 'todo-list-example',
  data() {
    return {
      newTodoText: '',
      todos: [
        {
          id: 1,
          title: 'Do the dishes',
        },
        {
          id: 2,
          title: 'Take out the trash',
        },
        {
          id: 3,
          title: 'Mow the lawn',
        },
      ],
      nextTodoId: 4,
    }
  },
  methods: {
    addNewTodo: function () {
      this.todos.push({
        id: this.nextTodoId++,
        title: this.newTodoText,
      })
      this.newTodoText = ''
    },
  },
  components: { TodoItem },
}
</script>

<style scoped></style>
