import Vue from 'vue'
import Vuex from 'vuex'
import dayjs from 'dayjs'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    clickAccountKey: '',
    clickItem: {},
    // 历史图表
    stockHistoryModalVisible: false,
    // 交易操作相关
    stockTradeDrawerVisible: false,
    stockTradeDrawerPreIsTrade: false,
    form: {
      tradingTypeEnum: '',
      code: '',
      tradeOpTypeEnum: 'BUY',
      tradeOpDate: dayjs().format('YYYYMMDD'),
      tradeOpPrice: 2.0,
      tradeOpCount: 100,
      recordType: '',
      accountPlatform: '',
      accountCompany: '',
    },
  },
  getters: {},
  mutations: {
    updateClickAccountKey(state, clickAccountKey) {
      state.clickAccountKey = clickAccountKey
    },
    updateClickItem(state, clickItem) {
      state.clickItem = clickItem
    },
    updateStockHistoryModalVisible(state, stockHistoryModalVisible) {
      state.stockHistoryModalVisible = stockHistoryModalVisible
    },
    updateStockTradeDrawerVisible(state, stockTradeDrawerVisible) {
      state.stockTradeDrawerVisible = stockTradeDrawerVisible
    },
    updateStockTradeDrawerPreIsTrade(state, stockTradeDrawerPreIsTrade) {
      state.stockTradeDrawerPreIsTrade = stockTradeDrawerPreIsTrade
    },
    updateForm(state, newData) {
      state.form = { ...state.form, ...newData }
    },
  },
  actions: {},
  modules: {},
})
