<template>
  <!-- 悬浮展示操作 -->
  <a-popover trigger="hover" placement="right" :overlayStyle="{ width: '150px' }">
    <template slot="content">
      <a-button
        v-if="isHold"
        block
        type="primary"
        :style="{ margin: '1px' }"
        @click="showTradeDrawer(true)"
      >
        卖出
      </a-button>
      <a-button block type="primary" :style="{ margin: '1px' }" @click="showTradeDrawer(false)">
        买入
      </a-button>
      <a-button block type="primary" :style="{ margin: '1px' }" @click="showHistoryTradeGraph">
        交易图
      </a-button>
    </template>
    <!--  卡片内容  -->
    <a-card
      :bordered="false"
      :hoverable="true"
      :bodyStyle="{
        background: income > 0 ? '#db564d' : '#7EBB6CFF',
        borderRadius: '5px',
        margin: '10px 0',
        padding: '5px 20px',
      }"
      @click="isHold ? showTradeDrawer() : showHistoryTradeGraph()"
    >
      <p :style="{ margin: 0 }">
        {{ item.codeName }} {{ isHold ? '---' + item.latestInfo.priceClose : '' }}
      </p>
      <!--  持仓数据展示的信息  -->
      <p v-if="isHold" :style="{ margin: 0 }">{{ '- 持有天数：' + item.holdDayCnt }}</p>
      <p v-if="isHold" :style="{ margin: 0 }">
        {{
          '- 涨跌幅度：' + Number(item.holdIncomeRatio * 100).toFixed(2) + '% / ' + item.holdIncome
        }}
      </p>
      <p v-if="isHold && recordType === '可转债低风险'" :style="{ margin: 0 }">
        - 可转债溢价率：{{ item.premiumRate }}
        <br />
        - 可转债排序值：{{ item.sortValue }}
      </p>
      <!--  历史数据展示的信息  -->
      <p v-if="!isHold" :style="{ margin: 0 }">{{ '- 交易次数：' + item.tradeCnt }}</p>
      <p v-if="!isHold" :style="{ margin: 0 }">{{ '- 结算收益：' + item.totalAmount }}</p>
    </a-card>
  </a-popover>
</template>

<script>
export default {
  name: 'SingleStockItem',
  props: {
    recordType: String,
    accountCompany: String,
    accountPlatform: String,
    isHold: Boolean,
    item: Object,
    income: Number,
  },
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    // 打开交易栏
    showTradeDrawer(isSell) {
      this.$store.commit('updateStockTradeDrawerVisible', true)
      this.$store.commit('updateForm', {
        code: this.item.codeKey.code,
        tradingTypeEnum: this.item.codeKey.tradingTypeEnum,
        tradeOpTypeEnum: isSell ?? this.item.hold ? 'SELL' : 'BUY',
        tradeOpCount: this.item.holdCount,
        tradeOpPrice: this.item.latestInfo?.priceClose ?? 1.0,
        recordType: this.recordType,
        accountCompany: this.accountCompany,
        accountPlatform: this.accountPlatform,
      })
    },
    // 查看价格趋势图
    showHistoryTradeGraph() {
      this.$store.commit('updateStockHistoryModalVisible', true)
      this.$store.commit('updateClickAccountKey', this.recordType)
      this.$store.commit('updateClickItem', this.item)
    },
  },
}
</script>

<style scoped></style>
