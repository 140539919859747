import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ConvertBondView from '../views/analysis_account/AnalysisAccountView'
import TestV3View from '@/components/learning/v3/TestV3View'
import OptionAnalysis from '@/views/option_analysis/OptionAnalysis.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/vue',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
  },
  {
    path: '/convert_bond',
    name: 'convert_bond',
    component: ConvertBondView,
  },
  {
    path: '/option_analysis',
    name: 'option_analysis',
    component: OptionAnalysis,
  },
  {
    path: '/test',
    name: 'test_view',
    component: TestV3View,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
