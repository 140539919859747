<template>
  <a-drawer :width="450" :visible="this.$store.state.stockTradeDrawerVisible" @close="handleClose">
    <a-form-model :model="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 17 }">
      <!--   股票列表   -->
      <a-form-model-item label="股票列表">
        <a-select
          placeholder="选择交易对象"
          showSearch
          allowClear
          :filterOption="false"
          :value="form.code"
          @search="handleSearch"
          @select="handleSelect"
          @change="handleChange"
        >
          <a-select-option v-for="stock in stockList" :key="stock.id" :value="stock.code">
            {{ stock.code + '(' + stock.name + ')' }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <!--   交易操作   -->
      <a-form-model-item label="交易" :wrapper-col="{ span: 20 }">
        <a-row>
          <a-col :span="6">
            <a-select v-model="form.tradeOpTypeEnum">
              <a-select-option value="BUY">买入</a-select-option>
              <a-select-option value="SELL">卖出</a-select-option>
            </a-select>
          </a-col>
          <a-col :span="6" :offset="1">
            <a-input-number v-model="form.tradeOpCount" />
          </a-col>
          <a-col :span="6" :offset="1">
            <a-input-number v-model="form.tradeOpPrice" :precision="3" />
          </a-col>
        </a-row>
      </a-form-model-item>
      <!--   交易时间   -->
      <a-form-model-item label="交易时间" :wrapper-col="{ span: 17 }">
        <a-date-picker v-model="form.tradeOpDate" allowClear valueFormat="YYYYMMDD" />
      </a-form-model-item>
      <!--   记录类型   -->
      <a-form-model-item label="记录类型">
        <a-select v-model="form.recordType">
          <a-select-option v-for="item in recordTypeList" :key="item" :value="item">
            {{ item }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <!--   账户的平台   -->
      <a-form-model-item label="账户平台">
        <a-select v-model="form.accountPlatform">
          <a-select-option v-for="item in accountPlatformList" :key="item" :value="item">
            {{ item }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <!--   对应的证券公司   -->
      <a-form-model-item label="证券公司">
        <a-select v-model="form.accountCompany">
          <a-select-option v-for="item in accountCompanyList" :key="item" :value="item">
            {{ item }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <!--   表单提交   -->
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="handleSubmit"> 提交 </a-button>
        <a-button style="margin-left: 10px" @click="handleClose"> 取消 </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import dayjs from 'dayjs'

export default {
  name: 'StockTradeDrawer',
  data() {
    return {
      recordTypeList: [
        '可转债低风险',
        '可转债YTM轮动',
        '简单交易v2',
        'GridPlus',
        '权证对冲',
        '可转债活跃',
      ],
      accountPlatformList: ['同花顺', '涨乐财富通', '云锋有鱼'],
      accountCompanyList: ['中信证券', '长城证券', '华泰证券', '云锋证券'],
      stockList: [],
      form: {
        tradingTypeEnum: '',
        code: '',
        tradeOpTypeEnum: 'BUY',
        tradeOpDate: dayjs().format('YYYYMMDD'),
        tradeOpPrice: 1.0,
        tradeOpCount: 100,
        recordType: '',
        accountPlatform: '',
        accountCompany: '',
      },
      // form: this.$store.state.form,
      curOptionData: '',
    }
  },
  methods: {
    // 获取 stock 列表
    fetchData(searchKey) {
      axios
        .post('/api/back/stock/info/list', {
          searchKey,
          typeList: [this.form.tradingTypeEnum].filter(r => null != r && '' != r),
        })
        // .then(res => console.log(res))
        .then(response => {
          this.stockList = response.data.data
          if (this.form.code != undefined && this.form.code.length != 0) {
            this.handleSelect(this.form.code)
          }
        })
    },
    // 搜索
    handleSearch(searchKey) {
      this.form.tradingTypeEnum = null
      this.fetchData(searchKey)
    },
    // stock 选择变更时
    handleSelect(code) {
      this.curOptionData = this.stockList.filter(r => r.code === code)[0]
      // this.form = { ...this.curOptionData }
      this.form = { ...this.form, ..._.pick(this.curOptionData, ['code', 'tradingTypeEnum']) }
    },
    handleChange(code) {
      if (_.isEmpty(code)) {
        // 清除
        this.form.tradingTypeEnum = null
        this.form.code = null
      }
    },
    // 关闭 drawer
    handleClose() {
      this.$store.commit('updateStockTradeDrawerVisible', false)
    },
    // 点击提交
    handleSubmit() {
      axios.post(
        '/api/v1/analysis/record',
        {},
        {
          params: this.form,
        }
      )
      // if (this.form.recordType === '可转债低风险') {
      //   axios.post(
      //     '/api/v1/analysis/convert_bond/record',
      //     {},
      //     {
      //       params: this.form,
      //     }
      //   )
      // } else {
      //   console.log(this.$store.state.form)
      // }
      this.$store.commit('updateStockTradeDrawerVisible', false)
      this.$store.commit('updateStockTradeDrawerPreIsTrade', true)
    },
  },
  // 生命周期 hook
  // mounted() {
  //   this.fetchData('')
  // },
  watch: {
    '$store.state.stockTradeDrawerVisible': function (newData, oldData) {
      // 打开时，从全局赋值 form 信息
      if (newData && !oldData) {
        this.form = { ...this.$store.state.form }
        if (this.form.code != undefined && this.form.code.length != 0) {
          this.fetchData(this.form.code)
        }
      }
    },
  },
}
</script>

<style scoped></style>
