<template>
  <div id="info">
    <img alt="Vue logo" src="@/assets/logo.png" />
    <HelloWorld msg="Welcome to Vue.js App" />
    <a-date-picker v-model="date"></a-date-picker>
    <todo-list-example></todo-list-example>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import dayjs from 'dayjs'
import TodoListExample from '@/components/learning/v1/TodoListExample'

export default {
  name: 'HomeView',
  components: {
    TodoListExample,
    HelloWorld,
  },
  data() {
    return {
      date: dayjs(),
    }
  },
}
</script>

<style>
#info {
  text-align: center;
}
</style>
