<template>
  <div
    :style="{
      background: '#ececec',
      padding: '20px',
      borderRadius: '15px',
      overflow: 'scroll',
      whiteSpace: 'nowrap',
      height: '100%',
    }"
  >
    <!--  每一个账户卡片  -->
    <a-card
      class="account_card"
      v-for="account in all_data"
      :key="account.recordType"
      :title="'策略：' + account.recordType"
      :bordered="false"
      :style="{
        width: '350px',
        borderRadius: '10px',
        display: 'inline-block',
        margin: '10px',
        verticalAlign: 'top',
        wordWrap: 'break-word',
        whiteSpace: 'normal',
      }"
      :body-style="{ padding: '10px 0px', display: 'block' }"
      :loading="cardLoading"
    >
      <a slot="extra" @click="showAccountHoldCheckModal(account)">校验</a>
      <a-divider slot="extra" type="vertical" />
      <!--   右上角操作：交易   -->
      <a slot="extra" @click="handleShowDrawer(account)">交易</a>
      <!--  持仓检查    -->
      <AccountHoldCheckModal
        :visible="accountHoldCheckModalVisible"
        :holdList="checkHoldStockList"
        @disable="disableAccountHoldCheckModal"
      ></AccountHoldCheckModal>
      <!--   当前持仓的   -->
      <a-collapse defaultActiveKey="in_hold" :bordered="false" :style="{ background: 'white' }">
        <a-collapse-panel
          key="in_hold"
          :header="
            '当前持仓：' +
            account.holdStockList.length +
            ' / ' +
            retrieveAccountHoldIncome(account.holdStockList)
          "
          :style="{ border: 0 }"
        >
          <SingleStockItem
            v-for="item in account.holdStockList"
            :key="item.codeName"
            :record-type="account.recordType"
            :account-company="account.accountCompany"
            :account-platform="account.accountPlatform"
            :is-hold="true"
            :item="item"
            :income="item.holdIncome"
          >
          </SingleStockItem>
        </a-collapse-panel>
        <!--   历史     -->
        <a-collapse-panel
          key="history"
          :header="
            '已结算：' +
            account.historyStockList.length +
            ' / ' +
            retrieveAccountTotalAmount(account.historyStockList)
          "
          :style="{ border: 0 }"
        >
          <SingleStockItem
            v-for="item in account.historyStockList"
            :key="item.codeName"
            :record-type="account.recordType"
            :account-company="account.accountCompany"
            :account-platform="account.accountPlatform"
            :is-hold="false"
            :item="item"
            :income="item.totalAmount"
          >
          </SingleStockItem>
        </a-collapse-panel>
      </a-collapse>
    </a-card>
  </div>
</template>

<script>
import SingleStockItem from '@/views/analysis_account/SingleStockItem'
import AccountHoldCheckModal from '@/views/analysis_account/AccountHoldCheckModal'
import axios from 'axios'

export default {
  name: 'SingleAccountCard',
  components: {
    SingleStockItem,
    AccountHoldCheckModal,
  },
  methods: {
    handleShowDrawer(account) {
      this.$store.commit('updateStockTradeDrawerVisible', true)
      this.$store.commit('updateForm', {
        tradingTypeEnum: '',
        tradeOpTypeEnum: 'BUY',
        tradeOpCount: 100,
        recordType: account.recordType,
        accountCompany: account.accountCompany,
        accountPlatform: account.accountPlatform,
      })
    },
    updateAccountDataList() {
      this.cardLoading = true
      axios
        .get('/api/back/stock/record/all/v2')
        // .then(r => console.log(r.data))
        .then(response => {
          this.all_data = response.data.data
          this.cardLoading = false
        })
    },
    disableAccountHoldCheckModal() {
      this.accountHoldCheckModalVisible = false
    },
    showAccountHoldCheckModal(account) {
      this.accountHoldCheckModalVisible = true
      this.checkHoldStockList = account.holdStockList
    },
    retrieveAccountTotalAmount(holdList) {
      return holdList
        .map(r => r.totalAmount)
        .reduce((a, b) => a + b, 0)
        .toFixed(2)
    },
    retrieveAccountHoldIncome(holdList) {
      return holdList
        .map(r => r.holdIncome)
        .reduce((a, b) => a + b, 0)
        .toFixed(2)
    },
  },
  data() {
    return {
      all_data: {},
      accountHoldCheckModalVisible: false,
      checkHoldStockList: [],
      cardLoading: true,
    }
  },
  // 生命周期 hook
  mounted() {
    this.updateAccountDataList()
  },
  watch: {
    '$store.state.stockTradeDrawerVisible': function (newData, oldData) {
      // 关闭时，如果之前有交易，则重新获取持仓列表
      if (!newData && oldData && this.$store.state.stockTradeDrawerPreIsTrade) {
        setTimeout(this.updateAccountDataList(), 300)
        this.$store.commit('updateStockTradeDrawerPreIsTrade', false)
      }
    },
  },
}
</script>

<style scoped></style>
