<template>
  <div>
    <SingleAccountCard></SingleAccountCard>
    <StockTradeDrawer></StockTradeDrawer>
    <stock-history-record-modal></stock-history-record-modal>
  </div>
</template>

<script>
import SingleAccountCard from '@/views/analysis_account/SingleAccountCard'
import StockTradeDrawer from '@/views/analysis_account/StockTradeDrawer'
import StockHistoryRecordModal from '@/views/analysis_account/StockHistoryRecordModal'

export default {
  name: 'ConvertBondView',
  components: { StockHistoryRecordModal, SingleAccountCard, StockTradeDrawer },
  methods: {},
  data() {
    return {}
  },
}
</script>

<style></style>
