<template>
  <div>
    <!--  ETF 选择  -->
    <template>
      <!--  ETF 选择器：选择期权的目标  -->
      <a-select v-model="selectedETFCode" @change="fetchAll">
        <a-select-option v-for="item in etfList" :key="item.code" :value="item.code">
          {{ item.name }} - {{ item.code }}
        </a-select-option>
      </a-select>

      <!-- vue-echarts 折线图显示 ETF 的价格趋势  -->
      <v-chart
        className="chart"
        :option="etfChartOption"
        :loading="this.etfPricePicLoading"
        :style="{ height: '400px' }"
      />
    </template>

    <!--  期权表格相关  -->
    <template>
      <!--   添加行   -->
      <a-row>
        <a-col :span="6">
          <!--    选择期权    -->
          <a-cascader
            :options="optionCascaderList"
            :style="{ width: '95%', margin: '30' }"
            @change="value => (this.addRowSelectOptionId = value[2])"
            expandTrigger="hover"
          />
        </a-col>
        <a-col :span="3">
          <!--    选择日期      -->
          <a-date-picker
            :disabled-date="disabledDate"
            v-model="addRowSelectedDate"
            :style="{ width: '90%' }"
          />
        </a-col>
        <a-col :span="1">
          <a-button style="margin-bottom: 8px" @click="handleAddTableRow('BUY')">BUY</a-button>
        </a-col>
        <a-col :span="1">
          <a-button style="margin-bottom: 8px" @click="handleAddTableRow('SELL')">SELL</a-button>
        </a-col>
        <a-col :span="1">
          <a-button style="margin-bottom: 8px" @click="handleAddTableRowETF">ETF</a-button>
        </a-col>
      </a-row>
      <a-row>
        <!--   表格展示   -->
        <a-col :span="12">
          <a-table bordered :columns="this.columns" :dataSource="this.tableRowList">
            <template slot="showLine" slot-scope="text, record">
              <a-checkbox v-model="record.showLine" @change="handleOptionProfitUpdate" />
            </template>
            <template slot="option" slot-scope="text, record">
              {{ record.option.name }} ({{ record.option.code }})
            </template>
            <template slot="date" slot-scope="text, record">
              <a-date-picker
                :disabled-date="disabledDate"
                v-model="record.date"
                @change="(a, b) => handleRowDateChange(a, b, record)"
              />
            </template>
            <template slot="price" slot-scope="text, record">
              {{ record.price }}
            </template>
            <!--     删除行       -->
            <template slot="operation" slot-scope="text, record">
              <a-button @click="handleDeleteTableRow(record)"> Delete </a-button>
            </template>
          </a-table>
        </a-col>
        <!--   收益图     -->
        <a-col :span="12">
          <v-chart
            className="chart"
            :option="profitChartOption"
            :style="{ height: '400px' }"
            @click="handleClickOfProfitChart"
            :key="profitChartKey"
          />
        </a-col>
      </a-row>
    </template>
  </div>
</template>

<script>
import axios from 'axios'
import VChart from 'vue-echarts'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'OptionAnalysis',
  components: {
    VChart,
  },
  data() {
    // 图表事件监听
    this.chartEvents = {
      click: function (e) {
        console.log(e)
      },
    }
    return {
      tradeDateList: [],
      // etf 相关
      etfList: [],
      selectedETFCode: '',
      etfPriceLineDataSet: [],
      etfPricePicLoading: true,
      // 期权列表
      optionList: [],
      optionCascaderList: [],
      // 表格列表
      rowIndex: 1,
      addRowTradeType: 'BUY',
      addRowSelectedDate: moment(),
      addRowSelectOptionId: -1,
      tableRowList: [],
      columns: [
        {
          title: 'ShowLine',
          dataIndex: 'showLine',
          scopedSlots: { customRender: 'showLine' },
        },
        {
          title: 'Option/ETF',
          dataIndex: 'option',
          scopedSlots: { customRender: 'option' },
        },
        {
          title: 'Date',
          dataIndex: 'date',
          scopedSlots: { customRender: 'date' },
        },
        {
          title: 'TradeType',
          dataIndex: 'tradeType',
        },
        {
          title: 'Price',
          dataIndex: 'price',
          scopedSlots: { customRender: 'price' },
        },
        {
          title: 'Operation',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      // 期权组合收益曲线
      optionProfitUpdate: '',
      // 所有选中的期权价格列表
      optionPriceListMap: {},
      // 图表相关
      profitChartKey: 'profitChartKey' + _.random(0, 100000),
    }
  },
  // 初始化
  mounted: function () {
    var vm = this
    // 可交易日期
    axios.get('/api/back/trading_date/list').then(function (response) {
      vm.tradeDateList = response.data.data.dateList
    })
    // 期权 etf 列表
    axios.get('/api/back/option_analysis/target/list').then(function (response) {
      vm.etfList = response.data.data
      if (vm.etfList.length > 0) {
        vm.selectedETFCode = vm.etfList[0].code
        vm.fetchAll()
      }
    })
  },
  computed: {
    // selectedETFCode 对应的 etf
    selectedETF() {
      return this.etfList.find(item => item.code === this.selectedETFCode)
    },
    // 上方折线图的数据（ETF 折线 & 根据天合并option价格）
    etfChartDataset() {
      let dateRes = {}
      // etf 价格曲线
      this.etfPriceLineDataSet.forEach(item => {
        dateRes[item.tradeDateStr] = {
          tradeDateStr: item.tradeDateStr,
          priceClose: item.priceClose,
        }
      })
      // 不可删除，用来响应深层对象的变更
      console.log('etfChartDataset-optionProfitUpdate', this.optionProfitUpdate)
      // 合并 option 价格（根据 rowList，过滤选择时间之前的）
      this.tableRowList.forEach(item => {
        let startDateStr = item.date.format('YYYYMMDD')
        let priceList =
          item.tradeType === 'ETF'
            ? this.etfPriceLineDataSet
            : this.optionPriceListMap[item.option.code]
        if (!_.isNil(priceList)) {
          priceList
            .filter(priceItem => priceItem.tradeDateStr >= startDateStr)
            .forEach(priceItem => {
              // ETF 只能为买入操作，对于买入，收益就是价格差
              let profitPrice =
                item.tradeType === 'BUY' || item.tradeType === 'ETF'
                  ? priceItem.priceClose - item.price
                  : -priceItem.priceClose + item.price
              if (_.isNil(dateRes[priceItem.tradeDateStr].optionProfit)) {
                dateRes[priceItem.tradeDateStr].optionProfit = profitPrice
              } else {
                dateRes[priceItem.tradeDateStr].optionProfit += profitPrice
              }
            })
        }
      })
      // 把 dateRes 的 value 转成数组，并按 value 中的 tradeDateStr 正序排序
      let dateList = Object.values(dateRes).sort((a, b) => {
        return a.tradeDateStr > b.tradeDateStr ? 1 : -1
      })
      // console.log('etfChartDataset', dateList)

      return dateList
    },
    // etf 的价格曲线
    etfChartOption() {
      return {
        legend: {},
        // 显示同列数据
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
          },
        },
        // 数据
        dataset: {
          dimensions: ['tradeDateStr', 'priceClose', 'optionProfit'],
          source: this.etfChartDataset,
        },
        xAxis: { type: 'category' },
        // 坐标允许不从 0 开始
        yAxis: [{ scale: true }, { scale: true }],
        series: [
          {
            type: 'line',
            smooth: true,
            yAxisIndex: 0,
          },
          {
            type: 'line',
            smooth: true,
            yAxisIndex: 1,
          },
        ],
      }
    },
    // addRowSelectOptionId 对应的 option
    addRowSelectOption() {
      return this.optionList.find(item => item.id === this.addRowSelectOptionId)
    },
    // 选择的期权列表的收益曲线
    optionProfitChartDataset() {
      // 不可删除，用来响应深层对象的变更
      console.log('optionProfitChartDataset-optionProfitUpdate', this.optionProfitUpdate)
      if (_.isNil(this.etfPriceLineDataSet) || this.etfPriceLineDataSet.length === 0) {
        return []
      }
      // 收益计算
      let res = []
      // - 遍历起点，etfPriceLineDataSet 中价格最小值
      let minPrice =
        this.etfPriceLineDataSet
          .map(item => item.priceClose)
          .reduce((pre, cur) => {
            return pre < cur ? pre : cur
          }) * 0.9
      // - 遍历终点
      let maxPrice =
        this.etfPriceLineDataSet
          .map(item => item.priceClose)
          .reduce((pre, cur) => {
            return pre > cur ? pre : cur
          }) * 1.1
      // - 从 0 遍历到 10，间隔 0.1
      let cnt = 1
      for (let curPrice = minPrice; curPrice < maxPrice; curPrice += 0.0001) {
        // 如果 this.tableRowList 是空的，跳出
        if (this.tableRowList.length === 0) {
          break
        }
        // 遍历 this.tableRowList
        cnt++
        let isPush = cnt % 100 === 0
        let curLegend = { price: curPrice.toFixed(4) }
        let profit = this.tableRowList
          .map(item => {
            let res = 0.0
            // 如果是 ETF，根据买入价格计算收益
            if (item.tradeType === 'ETF') {
              res = curPrice - item.price
            } else {
              // 如果是期权，根据认沽认购计算收益
              let option = item.option
              let config = JSON.parse(option.extConfig)
              let basePrice = config.basePrice
              let contractTypeEnum = config.contractTypeEnum
              let optionTradePrice = item.price
              let tradeTypeEnum = item.tradeType
              if (contractTypeEnum === 'CALL') {
                // 认购（CALL）
                res =
                  curPrice < basePrice ? -optionTradePrice : curPrice - basePrice - optionTradePrice
              } else {
                // 认沽（PUT）
                res =
                  curPrice > basePrice ? -optionTradePrice : basePrice - curPrice - optionTradePrice
              }
              res = tradeTypeEnum === 'BUY' ? res : -res
            }
            isPush = isPush || (res < 1e-6 && res > -1e-6)
            // 单独添加
            if (item.showLine && isPush) {
              curLegend[item.option.name] = res.toFixed(4)
            }

            return res
          })
          //   求和
          .reduce((prev, curr) => prev + curr, 0)
        // 保留4位小数
        curLegend.profit = profit.toFixed(4)
        // 加入曲线
        isPush = isPush || (profit < 1e-6 && profit > -1e-6)
        // console.log(isPush, curPrice, profit)
        if (isPush) {
          res.push(curLegend)
        }
      }
      // console.log(res)
      return res
    },
    optionDimensions() {
      // 不可删除，用来响应深层对象的变更
      console.log('optionDimensions-optionProfitUpdate', this.optionProfitUpdate)
      // legend
      let res = ['price', 'profit']
      this.tableRowList.filter(item => item.showLine).forEach(item => res.push(item.option.name))
      // console.log(res)

      return res
    },
    optionSeries() {
      // 不可删除，用来响应深层对象的变更
      console.log('optionProfitList-optionProfitUpdate', this.optionProfitUpdate)
      // series
      return this.optionDimensions
        .filter(item => item !== 'price')
        .map(item => {
          return {
            type: 'line',
            smooth: true,
            showSymbol: false,
            name: item,
            encode: { x: 'price', y: item },
            lineStyle: {
              color: item === 'profit' ? 'red' : null,
            },
          }
        })
    },
    profitChartOption() {
      return {
        legend: {},
        // 显示同列数据
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
          },
        },
        // 数据
        dataset: {
          dimensions: this.optionDimensions,
          source: this.optionProfitChartDataset,
        },
        xAxis: { type: 'value', show: true, scale: true },
        // 坐标允许不从 0 开始
        yAxis: [{ scale: true }],
        series: this.optionSeries,
      }
    },
  },
  methods: {
    // 测试
    handleTest: function (a) {
      console.log('handleTest')
      // this.optionProfitUpdate = b
      console.log(a)
      // console.log('index', b)
      // console.log('indent', c)
      // console.log('expanded', d)
      // console.log(this.tableRowList)
    },
    // 非交易日期：不在 tradeDateList，或者当天之后的
    disabledDate(current) {
      return !this.tradeDateList.includes(current.format('YYYYMMDD')) || current.isAfter(moment())
    },
    // 获取有所基本信息
    fetchAll: function () {
      this.fetchTradingInfoList(this.selectedETF)
      this.fetchOptionList(this.selectedETF)
      this.fetchOptionCascaderList(this.selectedETF)
    },
    // 获取价格曲线
    fetchTradingInfoList: function (value) {
      this.etfPricePicLoading = true
      axios
        .post('/api/back/trading_info/basic/list', value)
        .then(response => response.data)
        .then(res => {
          // 从 response 获取 tradeDateStr 和 priceClose 两列数据，并截取最后 100 条
          this.etfPriceLineDataSet = res.data.slice(-100)
          this.etfPricePicLoading = false
        })
    },
    // 获取期权价格曲线
    fetchOptionTradingInfoList(value) {
      axios
        .post('/api/back/trading_info/basic/list', value)
        .then(response => response.data)
        .then(res => {
          // 如果不为空
          if (res.data.length > 0) {
            // 从 response 获取 tradeDateStr 和 priceClose 两列数据，并截取最后 100 条
            this.optionPriceListMap[res.data[0].code] = res.data.slice(-100)
            this.handleOptionProfitUpdate('fetchOptionTradingInfoList：' + res.data[0].code)
          }
        })
    },
    // 获取期权相关
    fetchOptionList: function (value) {
      var vm = this
      axios.post('/api/back/option_analysis/option/list', value).then(function (response) {
        // 解析 extConfig 中的 contractTypeEnum，basePrice,lastTradeDateStr 并排序
        vm.optionList = response.data.data
      })
    },
    fetchOptionCascaderList: function (value) {
      var vm = this
      axios.post('/api/back/option_analysis/option/list/cascader', value).then(function (response) {
        // 解析 extConfig 中的 contractTypeEnum，basePrice,lastTradeDateStr 并排序
        vm.optionCascaderList = response.data.data
      })
    },
    fetchOptionTradeInfo(option, moment, record) {
      axios
        .post(
          '/api/back/trading_info/basic/single?tradeDateStr=' + moment.format('YYYYMMDD'),
          option
        )
        .then(response => response.data)
        .then(res => {
          option.tradeInfo = res.data
          record.price = res.data.priceClose
        })
    },
    // 表格相关
    // - 添加行：添加行按钮
    handleAddTableRow(tradeType) {
      let record = {
        // key: this.addRowSelectOption.id + this.addRowSelectedDate.format('YYYYMMDD'),
        key: this.rowIndex++,
        tradeType: tradeType,
        option: this.addRowSelectOption,
        date: this.addRowSelectedDate,
        price: 0,
        showLine: true,
      }
      this.fetchOptionTradeInfo(this.addRowSelectOption, this.addRowSelectedDate, record)
      this.fetchOptionTradingInfoList(this.addRowSelectOption)
      this.tableRowList.push(record)
      this.handleOptionProfitUpdate('handleAddTableRow: ' + record.option.code)
    },
    // - 添加行：购买 ETF（ETF 盈利斜线）
    handleAddTableRowETF() {
      let record = {
        key: this.rowIndex++,
        tradeType: 'ETF',
        option: { code: this.selectedETFCode, name: this.selectedETF.name },
        date: this.addRowSelectedDate,
        price: this.etfPriceLineDataSet.find(
          item => item.tradeDateStr === this.addRowSelectedDate.format('YYYYMMDD')
        ).priceClose,
        showLine: true,
      }
      this.tableRowList.push(record)
      this.handleOptionProfitUpdate('handleAddTableRow: ' + record.option.code)
    },
    // - 行内日期变更
    handleRowDateChange(moment, dateStr, record) {
      // 对于期权，重新获取价格；对于 ETF，从列表中获取价格
      if (record.tradeType === 'ETF') {
        record.price = this.etfPriceLineDataSet.find(
          item => item.tradeDateStr === moment.format('YYYYMMDD')
        ).priceClose
      } else {
        this.fetchOptionTradeInfo(record.option, moment, record)
      }
      this.handleOptionProfitUpdate('handleRowDateChange: ' + dateStr)
    },
    // - 删除行
    handleDeleteTableRow(record) {
      // console.log('delete', record)
      // console.log('tableRowList', this.tableRowList)
      this.tableRowList = this.tableRowList.filter(item => item.key !== record.key)
      this.optionPriceListMap[record.option.code] = []
      this.handleOptionProfitUpdate('handleDeleteTableRow:' + record.option.code)
    },
    // 表格变更时调用，用于响应
    handleOptionProfitUpdate(value) {
      this.optionProfitUpdate = value
    },
    // 图表相关
    handleClickOfProfitChart(e) {
      console.log(e)
      let series = this.optionSeries.find(item => item.name === e.seriesName)
      let v = e.value[e.seriesName]
      if (series.markPoint === undefined) {
        series.markPoint = {
          symbol: 'circle',
          symbolSize: 10,
          data: [],
        }
      }
      series.markPoint.data.push({ value: e.value.price, xAxis: e.value.price, yAxis: v })
      console.log(this.optionSeries)
      this.profitChartKey = 'profitChartKey' + _.random(0, 100000)
      console.log(this.profitChartKey)
    },
  },
}
</script>
